<template>
  <div>
    <img src="/monthlyReviewImages/finalized.png" style="margin-bottom: 80px" />

    <div id="greatWorkText">
      Great work! Your {{ toMonthName($route.params.month) }} commissions report is now finalized.
    </div>

    <div id="downloadReportContainer">
      <v-btn color="primary" @click.prevent="downloadCompleteSummary">
        Download Report
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        download: false,
      },
    }
  },
  methods: {
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023,parseInt(monthNumber) - 1,1);

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },
    downloadCompleteSummary() {
      if (this.loading.download) {
        return
      }

      this.loading.download = true

      this.$api.commissionMonthlyReview
        .exportDetailed({
          date: this.date,
        })
        .then(({ data }) => {
          window.location = data.file

          setTimeout(() => {
            this.loading.download = false
          }, 1500)
        })
        .catch(() => {
          this.loading.download = false
        })
    },
  },
  computed: {
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
  },
}
</script>

<style lang="scss" scoped>
#greatWorkText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #3a3541;

  margin-bottom: 40px;
}

#downloadReportContainer {
  padding-bottom: 185px;
}
</style>
