<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" :disabled="processing.finalize">
          Finalize Commissions
          <v-icon size="20" right>arrow_forward</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="modalTitleText">
          Finalize Report
        </v-card-title>

        <v-card-text class="modalBodyText">
          You are finalizing monthly commissions report. Once finalized, the report will be locked to prevent further
          changes. Press “Finalize Report” to confirm.
          <br /><br />
          You will have access to view & download the finalized pay period at any time in the future.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.prevent="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click.prevent="confirmFinalize">
            Finalize Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      processing: {
        finalize: false,
      },
    }
  },
  methods: {
    ...mapMutations({
      setStepsStatuses: 'commissions/monthlyReview/setStepsStatuses',
    }),
    confirmFinalize() {
      this.$api.commissionMonthlyReview
        .finalize({
          date: this.date,
        })
        .then(() => {
          this.processing.finalize = false
          this.$emit('completed')
          this.dialog = false

          this.$api.commissionMonthlyReview
            .completeStep({
              date: this.date,
              step: 'finalize',
            })
            .then(({ data }) => {
              this.setStepsStatuses(data)
            })
        })
        .catch(() => {
          this.processing.finalize = false
        })
    },
  },
  computed: {
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modalTitleText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3a3541;
}

::v-deep .modalBodyText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3541;
}
</style>
