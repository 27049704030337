var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isFinalized)?_c('div',{attrs:{"id":"statementContentHeaderContainer"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{attrs:{"id":"statementContentContainerTitle"}},[_c('span',{staticClass:"stepNumber"},[_vm._v(_vm._s('0' + _vm.activeStep))]),_vm._v(" Finalize Report ")]),_c('div',{staticClass:"text-right"},[_c('finalize-button')],1)])]):_vm._e(),(!_vm.isFinalized)?_c('div',{attrs:{"id":"summaryPanel"}},[_c('table',{attrs:{"id":"summaryTable"}},[_vm._m(0),_c('tbody',_vm._l((_vm.statements),function(statement){return _c('tr',{key:statement.id},[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'commission-statement.producer',
                params: {
                  year: _vm.$route.params.year,
                  month: _vm.$route.params.month,
                  producer: statement.producer.id,
                },
              }}},[_vm._v(" "+_vm._s(statement.producer.name)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.toMonthName(_vm.$route.params.month))+" "+_vm._s(_vm.$route.params.year))]),_c('td',[_c('table',{attrs:{"id":"totalsTable"}},[_c('tr',[_c('td',[_vm._v(" Subtotal: ")]),_c('td',[_vm._v("$"+_vm._s(statement.subtotal.toFixed(2)))])]),_vm._l((statement.bonuses_and_fees),function(bonus){return _c('tr',{key:bonus.id},[_c('td',[_vm._v(_vm._s(bonus.label)+":")]),_c('td',[(bonus.value < 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v("$"+_vm._s(Math.abs(bonus.value).toFixed(2)))])])}),_c('tr',[_vm._m(1,true),_c('td',[_c('strong',[(statement.total < 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v("$"+_vm._s(Math.abs(statement.total).toFixed(2)))])])])],2)]),_c('td',[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.downloadProducerSummary(statement.producer.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDownload))])],1)],1)])}),0)])]):_vm._e(),(_vm.isFinalized)?_c('div',{attrs:{"id":"finalizedPanel"}},[_c('completed-overlay')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v("Producer Name")]),_c('th',[_vm._v("Pay Period")]),_c('th',[_vm._v("Producer Commissions")]),_c('th',[_vm._v("Download Report")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Total Commission:")])])}]

export { render, staticRenderFns }