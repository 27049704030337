<template>
  <div>
    <div id="statementContentHeaderContainer" v-if="!isFinalized">
      <div class="d-flex justify-space-between">
        <h3 id="statementContentContainerTitle">
          <span class="stepNumber">{{ '0' + activeStep }}</span>
          Finalize Report
        </h3>
        <div class="text-right">
          <finalize-button />
        </div>
      </div>
    </div>

    <div id="summaryPanel" v-if="!isFinalized">
      <table id="summaryTable">
        <thead>
          <th>Producer Name</th>
          <th>Pay Period</th>
          <th>Producer Commissions</th>
          <th>Download Report</th>
        </thead>
        <tbody>
          <tr v-for="statement in statements" :key="statement.id">
            <td>
              <router-link
                :to="{
                  name: 'commission-statement.producer',
                  params: {
                    year: $route.params.year,
                    month: $route.params.month,
                    producer: statement.producer.id,
                  },
                }"
              >
                {{ statement.producer.name }}
              </router-link>
            </td>
            <td>{{ toMonthName($route.params.month) }} {{ $route.params.year }}</td>
            <td>
              <table id="totalsTable">
                <tr>
                  <td>
                    Subtotal:
                  </td>
                  <td>${{ statement.subtotal.toFixed(2) }}</td>
                </tr>
                <tr v-for="bonus in statement.bonuses_and_fees" :key="bonus.id">
                  <td>{{ bonus.label }}:</td>
                  <td><span v-if="bonus.value < 0">-</span>${{ Math.abs(bonus.value).toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Total Commission:</strong>
                  </td>
                  <td>
                    <strong
                      ><span v-if="statement.total < 0">-</span>${{ Math.abs(statement.total).toFixed(2) }}</strong
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <v-btn text icon @click="downloadProducerSummary(statement.producer.id)">
                <v-icon>{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="finalizedPanel" v-if="isFinalized">
      <completed-overlay />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiDownload } from '@mdi/js'
import FinalizeButton from './finalize/FinalizeButton.vue'
import CompletedOverlay from './finalize/CompletedOverlay.vue'
import * as EVENTS from '@/config/globals/trackingEvents'

export default {
  props: ['activeStep'],
  components: {
    FinalizeButton,
    CompletedOverlay,
  },
  data() {
    return {
      icons: {
        mdiDownload,
      },
      loading: {
        download: false,
      },
      statements: [],
    }
  },
  methods: {
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023,parseInt(monthNumber) - 1,1);

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },

    loadSummary() {
      this.$api.commissionMonthlyReview.summary({ date: this.date }).then(({ data }) => {
        this.statements = data
      })
    },
    downloadProducerSummary(producerId) {
      if (this.loading.download) {
        return
      }

      this.loading.download = true

      this.$api.commissionStatements
        .export({
          date: this.date,
          user: producerId,
        })
        .then(({ data }) => {
          this.$tracking.event(EVENTS.DOWNLOAD_COMMISSIONS_REPORT, {
            report: 'Commissions Statement Report',
            date: this.date,
            user: producerId,
          })

          window.location = data.file
          setTimeout(() => {
            this.loading.download = false
          }, 1500)
        })
        .catch(() => {
          this.loading.download = false
        })
    },
  },
  mounted() {
    this.loadSummary()
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
      activeDate: 'commissions/monthlyReview/getActiveDate',
      currentStep: 'commissions/monthlyReview/getCurrentStep',
      isFinalized: 'commissions/monthlyReview/getFinalized',
    }),
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
  },
}
</script>

<style lang="scss" scoped>
#statementContentHeaderContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 2px;
}

#summaryPanel {
  margin-bottom: 2px;
  background: #fff;
}

#finalizedPanel {
  background: #fff;
  padding-top: 48px;
  text-align: center;
}

#totalsTable {
  width: 100%;
  max-width: 300px;
  td {
    border: none;
    padding: 0px 0px !important;
    vertical-align: top;
  }

  tr td:last-child {
    text-align: right;
  }
}

#summaryTable {
  width: 100%;
  border: none;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3541;

  thead {
    th {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
      color: #3a3541;
      text-align: left;
      padding: 16px 32px;
    }
  }

  tbody {
    tr {
      td {
        border: none;
        padding: 16px 32px;
        vertical-align: top;
      }
    }
  }
}
</style>
